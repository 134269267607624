<script>
	import Nav from '../components/Nav.html';

	export let segment;
</script>

<style>
	main {
		position: relative;
		max-width: 56em;
		background-color: white;
		padding: 2em;
		margin: 0 auto;
		box-sizing: border-box;
        padding-top: 4em;
	}
</style>

<Nav {segment}/>

<main>
	<slot></slot>
</main>