<script>
    import { register, init, _, addMessages} from 'svelte-i18n';

    export let segment;

    import en from '../../langs/en.json';
//    register('en', () => import('../langs/en.json'));
    addMessages('en', en);
    init({
        fallbackLocale: 'en',
        initialLocale: {
            navigator: true,
        },
    });
</script>

<nav>
    <ul>
        <li><a class:selected='{segment === undefined}' href='.'>{$_('title')}</a></li>
        <li><a class:selected='{segment === "about"}' href='about'>about</a></li>

        <!-- for the blog link, we're using rel=prefetch so that Sapper prefetches
             the blog data when we hover over the link or tap it on a touchscreen -->
        <!--<li><a rel=prefetch class:selected='{segment === "blog"}' href='blog'>blog</a></li>-->
    </ul>
</nav>


<style>
    nav {
        position: fixed;
        width: 30000px;
        z-index: 1000;
        background: white;
    }
    nav {
        border-bottom: 1px solid rgba(255,62,0,0.1);
        font-weight: 300;
        padding: 0 1em;
    }

    ul {
        margin: 0;
        padding: 0;
    }

    /* clearfix */
    ul::after {
        content: '';
        display: block;
        clear: both;
    }

    li {
        display: block;
        float: left;
    }

    .selected {
        position: relative;
        display: inline-block;
    }

    .selected::after {
        position: absolute;
        content: '';
        width: calc(100% - 1em);
        height: 2px;
        background-color: rgb(255,62,0);
        display: block;
        bottom: -1px;
    }

    a {
        text-decoration: none;
        padding: 1em 0.5em;
        display: block;
    }
</style>